import { useRef, useState, useEffect } from "react";
import baseURL from "../../services/BaseUrl.js";
import { Document, Page, pdfjs } from "react-pdf";
import YouTube from "react-youtube";

import {
  Box,
  Flex,
  Text,
  Icon,
  Button,
  HStack,
  Input,
  Center,
  useToast,
  Link,
} from "@chakra-ui/react";
import {
  FaFile,
  FaTimesCircle,
  FaCloudUploadAlt,
  FaVideo,
} from "react-icons/fa";
import Axios from "../../services/AxiosConfig";
import Loader from "../../helpers/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Laporan = () => {
  const inputRef = useRef(null);
  const toast = useToast();
  const docs = [
    { uri: require("examplepdf.pdf") }, // Local File
  ];

  const [loadingGet, setLoadingGet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [laporan, setLaporan] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    getLaporanAkhir();
  }, []);

  const getLaporanAkhir = async () => {
    setLoadingGet(true);
    await Axios.get("mahasiswa/getLaporanAkhir")
      .then(({ data }) => {
        setLaporan(data.laporan);
        console.info(data);
        setLoadingGet(false);
      })
      .catch((error) => {
        const {
          status,
          data: { message },
        } = error.response;
        setLoadingGet(false);
        toast({
          title: `Gagal (${status})!`,
          description: `${message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handleOpenFileInput = () => {
    inputRef.current.click();
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0],
      ext = file.type;

    if (ext !== "application/pdf") {
      return setErrorFile(true);
    }
    setSelectedFile(file);
  };

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const handleSubmitForm = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("laporan", selectedFile);
    formData.append("link", link);

    await Axios.post("mahasiswa/uploadLaporan", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        const {
          status,
          data: { message },
        } = res;
        toast({
          title: "Sukses!",
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
        getLaporanAkhir();
      })
      .catch((error) => {
        const {
          status,
          data: { message },
        } = error.response;
        setLoading(false);
        toast({
          title: `Gagal (${status})!`,
          description: `${message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box px={2} py={3} bg="#F5DF99">
        <Text fontWeight="bold">Laporan Akhir</Text>
      </Box>
      {loadingGet ? (
        <Loader />
      ) : (
        <>
          {laporan !== null ? (
            <AlreadyUploaded
              laporan={laporan}
              setLaporan={() => setLaporan(null)}
            />
          ) : (
            <Box py={2}>
              <Box bg="white" mt={3} p={4}>
                <Text mb={1}>File laporan</Text>
                <Text
                  fontSize={{ base: "12px", md: "12px" }}
                  color="gray.400"
                  mb={1}
                >
                  Unggah file laporan kamu dalam format PDF dengan ukuran
                  maksimal 5 MB. <br />
                </Text>

                {selectedFile.length < 1 ? (
                  <>
                    <Box
                      as={Flex}
                      justifyContent="center"
                      alignItems="center"
                      w="full"
                      bg="white"
                      border={`1px dashed ${errorFile ? "#C75A58" : ""}`}
                      h="80px"
                    >
                      <Button
                        colorScheme="teal"
                        leftIcon={<FaCloudUploadAlt />}
                        size="sm"
                        borderRadius="sm"
                        onClick={handleOpenFileInput}
                      >
                        Pilih File
                      </Button>
                      <input
                        ref={inputRef}
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => handleChangeFile(e)}
                      />
                    </Box>
                    {errorFile && (
                      <Text mt={1} fontSize="xs" color="red.600">
                        Hanya file pdf saja, silahkan pilih file lain!
                      </Text>
                    )}
                  </>
                ) : (
                  <Box
                    w="full"
                    mt={3}
                    as={Flex}
                    alignItems="center"
                    border="1px dashed"
                    borderColor="teal"
                    p={4}
                    justifyContent="space-between"
                    _hover={{ bg: "#F5DF99", cursor: "pointer" }}
                  >
                    <Box as={Flex}>
                      <Icon as={FaFile} fontSize="2xl" color="teal" />
                      <Box ml={2} as={Flex} alignItems="center">
                        <Text fontSize="md">{selectedFile.name}</Text>
                        <Text fontSize="10px" color="gray.500" ml={2}>
                          ({bytesToSize(selectedFile.size)})
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      justifyContent="center"
                      alignItems="center"
                      as={Flex}
                      p="absolute"
                    >
                      <Icon
                        as={FaTimesCircle}
                        onClick={() => {
                          setSelectedFile([]);
                          setErrorFile(false);
                        }}
                        fontSize="xl"
                        _hover={{ cursor: "pointer", color: "red.500" }}
                      />
                    </Box>
                  </Box>
                )}

                <Text mb={1} mt={4}>
                  Link video kegiatan
                </Text>
                <Text
                  mb={1}
                  fontSize={{ base: "12px", md: "12px" }}
                  color="gray.400"
                >
                  Silahkan masukkan link url video anda (disarankan link
                  youtube){" "}
                </Text>
                <Input
                  placeholder="Masukkan link disini..."
                  borderRadius="0"
                  _focus={{ border: "1px" }}
                  border="1px"
                  borderColor="gray.600"
                  onChange={(e) => setLink(e.target.value)}
                />
                <br />
                <Center>
                  <Button
                    size="md"
                    height="48px"
                    width="250px"
                    mt={6}
                    colorScheme="teal"
                    onClick={handleSubmitForm}
                    disabled={loading}
                    isLoading={loading}
                  >
                    Simpan laporan
                  </Button>
                </Center>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Laporan;

const AlreadyUploaded = ({
  laporan: { laporan, link_kegiatan_magang, updated_at },
  setLaporan,
}) => {
  const opts = {
    height: "300",
    width: "400",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleUpdate = () => {
    setLaporan();
  };

  return (
    <Box mt={3} bg="#fff" p={4}>
      <Box w="full">
        <Box>
          <Text fontSize="12px" color="gray.600" as="i">
            Di unggah {updated_at}.
            <Text
              as={Link}
              href="#"
              color="blue.900"
              onClick={handleUpdate}
              underline
            >
              {" "}
              Ubah
            </Text>
          </Text>
        </Box>
        <Text fontWeight="bold" mt={5} mb={2}>
          Laporan
        </Text>
        <Box
          border="1px dashed"
          p={3}
          as={Flex}
          justifyContent="space-between"
          alignItems="center"
          mb={7}
        >
          <HStack>
            <Icon as={FaFile} color="teal" />
            <a
              href={baseURL + "assets/upload/laporan/" + laporan}
              color="blue.600"
              target="_blank"
              fontSize={{ base: "12px", md: "sm" }}
            >
              {laporan}
            </a>
          </HStack>
        </Box>

        <Text fontWeight="bold" mb={2}>
          Video kegiatan{" "}
        </Text>
        <Box
          border="1px dashed"
          p={3}
          as={Flex}
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <HStack>
            <Icon as={FaVideo} color="teal" />
            <Link href="#" color="blue.600">
              {link_kegiatan_magang}
            </Link>
          </HStack>
        </Box>
      </Box>

      <YouTube videoId={link_kegiatan_magang} opts={opts} />

      {/*<Document file={{url: `http://localhost/backend-mbkm/assets/upload/laporan/1654957204_5380a9f6a24db52b2acd.pdf`}} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => console.log("Inside Error", error)}>
			        <Page pageNumber={pageNumber} />
			      </Document>
			      <p>
			        Page {pageNumber} of {numPages}
			      </p>*/}
    </Box>
  );
};
