import Axios from "../../services/AxiosConfig"
import { useState, useRef } from "react";
import { Text, Box, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Stack, HStack, VStack, InputGroup, InputLeftAddon, Select, Flex, Button, Center, Icon, Image,
	Modal,
	  ModalOverlay,
	  ModalContent,
	  ModalHeader,
	  ModalFooter,
	  ModalBody,
	  ModalCloseButton,
	  useToast
 } from "@chakra-ui/react";
 import { useNavigate } from "react-router-dom";
import { CKEditor } from 'ckeditor4-react';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { FaImage, FaTimesCircle, FaCloudUploadAlt} from "react-icons/fa";

const month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];


const TambahLogbook = () => {

	const year = new Date().getFullYear();
 	const toast = useToast();
 	let navigate = useNavigate();

	let tgl = [];
	for(let i = 1; i<=31; i++) {
		tgl.push(i);
	} 
	const inputRef = useRef(null);
	const[loading, setLoading]					= useState(false);
	const[lampiran, setLampiran] 				= useState([]);
	const[mingguKe, setMingguKe]				= useState("");
	const[tanggal, setTanggal]					= useState("");
	const[bulan, setBulan]						= useState("");
	const[tahun, setTahun]						= useState("");
	const[errorFileCount, setFileErrorCount] 	= useState(false);
	const[imageUrl, setImageUrl] 				= useState([])
	const[modalPreview, setModalPreview] 		= useState(false)

	const[namaKegiatan, setNamaKegiatan] = useState("")
	const[tujuanKegiatan, setTujuanKegiatan] = useState("")
	const[catatanKegiatan, setCatatanKegiatan] = useState("")
	const[pemecahanMasalah, setPemecahanMasalah] = useState("")
	const[kesimpulan, setKesimpulan] = useState("")
	const[kegiatanSelanjutnya, setkegiatanSelanjutnya] = useState("")


	const[lampiran1, setLampiran1] = useState([])

	const handleOpenFileInput = () => {
	    inputRef.current.click();
	 };

	 const handleChangeLampiran =  (e) => {
	 	const filesUpload = e.target.files;
	 	setLampiran1(filesUpload)
	 	const newArray = Array.from(filesUpload);

	 	const BreakError = {};

	 	try {
		  newArray.forEach((file, index) => {

		    if (index === 4) {
		      throw BreakError;
		    }
		  	const objectUrl = URL.createObjectURL(file);
		    setLampiran(oldValue =>  [...oldValue, {
		    	'file': file,
		    	'url': objectUrl
		    }]);
		  });
		} catch (err) {
		  	setFileErrorCount(true)
		  if (err !== BreakError) {
		  		setFileErrorCount(true)
		  };
		}

	 }

	 function bytesToSize(bytes) {
	     let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	     if (bytes == 0) return '0 Byte';
	     let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	  }

	  const handRemoveLampiran = (e,index) => {
	  		setLampiran((lampiran) => lampiran.filter((_, index) => index !== 0));
	  		if(lampiran.length <= 4) {
	  			setFileErrorCount(false);
	  		}else{
	  			setFileErrorCount(true);
	  		} 
	  }

	  const handleClickImage = (url)  => {
	  		setImageUrl(url)
	  		setModalPreview(true)
	  }

	  const handleChangeCKEfitor = (e, input) => {
	  	const data = e.editor.getData();
	  	if(input === 'namaKegiatan') {
	  		setNamaKegiatan(data)
	  	}else if(input === 'tujuan') {
	  		setTujuanKegiatan(data)
	  	}else if(input === 'catatan'){
	  		setCatatanKegiatan(data)
	  	}else if(input === 'pemecahanMasalah') {
	  		setPemecahanMasalah(data)
	  	}else if(input === 'kesimpulan'){
	  		setKesimpulan(data)
	  	}else if(input === "rencanaSelanjutnya"){
	  		setkegiatanSelanjutnya(data)
	  	}
	  }

	  const handleSubmitForm = () => {

	  	setLoading(true);
	 	const newArray1 = Array.from(lampiran1);
	  	let formData = new FormData();

	  	newArray1.forEach((file, index) => {
	  		formData.append("lampiran"+index, file);
	  	});
	  	
	  	formData.append("mingguKe", mingguKe);
	  	formData.append("tanggal", tanggal);
	  	formData.append("bulan", bulan);
	  	formData.append("tahun", tahun);
	  	formData.append("namaKegiatan", namaKegiatan);
	  	formData.append("tujuanKegiatan", tujuanKegiatan);
	  	formData.append("catatan", catatanKegiatan);
	  	formData.append("pemecahanMasalah", pemecahanMasalah);
	  	formData.append("kesimpulan", kesimpulan);
	  	formData.append("kegiatanSelanjutnya", kegiatanSelanjutnya);


	  	Axios.post("mahasiswa/logbook", formData, {headers: {
	        "Content-Type" : "multipart/form-data"
	      }})
	  	.then(res => {
	  		const {status, data: {message}} = res;
	  		toast({
                title: 'Sukses!',
                description: message,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top"
              });
	  		 navigate("/mhs/logbook");
	  		 setLoading(false)
	  	}).catch(error => {
	  		const {status, data: {message}} = error.response;
	          setLoading(false)
	          toast({
	            title: `Gagal (${status})!`,
	            description: `${message}`,
	            status: 'error',
	            duration: 5000,
	            isClosable: true,
	            position: "top"
	          })
	  	})


	  }

	return(
		<Box w="100%">
			<Box px={2} py={3} bg="#F5DF99">
		        <Text fontWeight="bold">Tambah logbook</Text>
		      </Box>
			
		      <Box mt={3} bg="white" w="100%" p={3} style={{ fontSize: "10px" }}>
	      		 <Stack spacing={4} direction={['column', 'row']}>
		              <Box w={{base:"100%", md:"35%" }}>
		                <FormControl id="firstName" isRequired>
		                  <FormLabel fontSize="14px">Kegiatan</FormLabel>
		                  <InputGroup borderRadius="sm" size="sm">
						    <InputLeftAddon children='Minggu Ke-' />
						    <Input placeholder='ex. 5' type="number" onChange={e => setMingguKe(e.target.value)} />
						  </InputGroup>
		                </FormControl>
		              </Box>
		              <Box w="full">
		                <FormControl id="lastName">
		                  <FormLabel fontSize="14px">Waktu kegiatan</FormLabel>
		                  	<Box as={Flex} flexDir={{base: "column", md: "row"}}>
		                  		<InputGroup borderRadius="sm" size="sm" my={{base: "1", md: 0 }} w={{base: "full", md:"200px"}} mr={3}>
								    <InputLeftAddon w={{base: '80px', md:"80px"}} children='Tanggal' />
								    <Select placeholder='--pilih--' onChange={e => setTanggal(e.target.value)}>
									  {
									  	tgl.map((item, index) => (
									  		<option value={item} key={index}>{item}</option>
									  	))
									  }
									</Select>
								  </InputGroup>
								  <InputGroup borderRadius="sm" size="sm" my={{base: "1", md: 0 }} w={{base: "full", md:"200px"}} mr={3}>
								    <InputLeftAddon w={{base: '80px', md:"80px"}} children='Bulan' />
									    <Select placeholder='--pilih--' onChange={e => setBulan(e.target.value)}>
											 {
											 	month.map((item, index) => (
											 		<option value={item} key={index}>{item}</option>
											 	))
											 }
										</Select>
								  </InputGroup>
								  <InputGroup borderRadius="sm" size="sm" my={{base: "1", md: 0 }} w={{base: "full", md:"200px" }}>
								    <InputLeftAddon w={{base: '80px', md:"80px"}} children='Tahun' />
									    <Input type="text" disabled value={year} onChange={e => setTahun(e.target.value)} />
								  </InputGroup>
		                  	</Box>
		                </FormControl>
		              </Box>
		        </Stack>
		        <FormControl mt={7}>
	              <FormLabel fontSize="14px">Nama kegiatan / sub kegiatan</FormLabel>
	               <CKEditor
	               		onChange={e => handleChangeCKEfitor(e, 'namaKegiatan')}
		                initData={''}
		            />
	            </FormControl>

	            <FormControl mt={7}>
	              <FormLabel fontSize="14px">Tujuan kegiatan / sub kegiatan (sesuai program)</FormLabel>
	               <CKEditor
	               		onChange={e => handleChangeCKEfitor(e, 'tujuan')}
		                initData={''}
		            />
	            </FormControl>

	            <FormControl mt={7}>
	              <FormLabel fontSize="14px">Catatan rencana yang akan dilakukan</FormLabel>
	               <CKEditor
	               		onChange={e => handleChangeCKEfitor(e, 'catatan')}
		                initData={''}
		            />
	            </FormControl>

	            <FormControl mt={7}>
	              <FormLabel fontSize="14px">Pemecahan masalah</FormLabel>
	               <CKEditor
	               		onChange={e => handleChangeCKEfitor(e, 'pemecahanMasalah')}
		                initData={''}
		            />
	            </FormControl>

	            <FormControl mt={7}>
	              <FormLabel fontSize="14px">Kesimpulan dan saran</FormLabel>
	               <CKEditor
	               		onChange={e => handleChangeCKEfitor(e, 'kesimpulan')}
		                initData={''}
		            />
	            </FormControl>

	            <FormControl mt={7}>
	              <FormLabel fontSize="14px">Rencana kegiatan selanjutnya</FormLabel>
	               <CKEditor
	               		onChange={e => handleChangeCKEfitor(e, 'rencanaSelanjutnya')}
		                initData={''}
		            />
	            </FormControl>

	             <FormControl mt={7}>
	              <FormLabel fontSize="14px">Lampiran kegiatan</FormLabel>
	              	 <Text fontSize={{ base: "12px", md: "12px" }} color="gray.400" mb={1}>Unggah multiple file/gambar dengan ukuran maksumal 5 MB, Maksimal 4 gambar. <br />
			          </Text>
			           {
			           	lampiran.length > 0 ? (
			           		<span>
			           			{
			           				lampiran.map(({file, url}, index) => (
			           					<div key={index}>
			           						<Box onClick={() => handleClickImage(url)} _hover={{bg: "#F5DF99", cursor: "pointer"}} w="full" p={2} border="1px dashed" my={1} as={Flex} justifyContent="space-between">
			           							<Box as={Flex} alignItems="center">
			           								<Icon as={FaImage} fontSize="xl" color='teal' mr={2} />
			           								<Text fontSize="12px">{file.name}</Text>
			           								<Text fontSize="10px" color="gray.500" ml={2}>({bytesToSize(file.size)})</Text>
			           							</Box>
			           							<Box justifyContent="center" alignItems="center" as={Flex}>
							                        <Icon 
							                        	as={FaTimesCircle}  
							                        	fontSize="md" 
							                        	_hover={{ cursor: "pointer", color: "red.500" }}
							                        	onClick={() => handRemoveLampiran(index)}
							                        />
							                      </Box>
			           						</Box>
			           					</div>
			           				))
			           			}
			           		</span>
			           	):("")
			           }

			           {
			           		errorFileCount && (
			           			<Text color="red.700">Hanya dapat mengupload 4 gambar</Text>
			           		)
			           }

			          {
			          	lampiran.length >= 4 ? "" : (
			          		<Box as={Flex} justifyContent="center" alignItems="center" w="full" bg="white" border="1px dashed" h="80px" >
				            <Button disabled={loading} colorScheme='teal' leftIcon={<FaCloudUploadAlt />} size='sm' borderRadius="sm" onClick={handleOpenFileInput}>
				              Pilih File
				            </Button>
				            <input ref={inputRef} multiple  style={{ display: "none" }} type="file" onChange={ e => handleChangeLampiran(e)} />
				          </Box> 
			          	) 
			          }
	            </FormControl>

	            <Center>
		             <Button
		              size='md'
		              height='48px'
		              width='250px'
		              mt={7}
		              mb={6}
		              colorScheme='teal'
		              borderRadius="sm"
		              onClick={() => handleSubmitForm()}
		              disabled={loading}
		              isLoading={loading}
		            >
		              Simpan logbook
		            </Button>
		           </Center>

		      </Box>

		      <ModalPreviewImage url={imageUrl} modalPreview={modalPreview} setModalPreview={setModalPreview}  />

		</Box>
	)
}

export default TambahLogbook;

const ModalPreviewImage = ({url, modalPreview, setModalPreview}) => {
	return (
		<>
		<Modal isOpen={modalPreview} onClose={modalPreview}>
	        <ModalOverlay />

	        <ModalContent>
	         <ModalCloseButton color="#fff" onClick={() => setModalPreview(false)} />
	          <Image src={url} />
	        </ModalContent>
	      </Modal>
		</>
	)
}