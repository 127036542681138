
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import Axios from "services/AxiosConfig"
import { Text, Box, Flex, Stack, Select, Button, Center, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, useToast } from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import Loader from "helpers/Loader";
import { BiChevronLeft } from "react-icons/bi";




const BimbinganDpl = () => {
	let navigate = useNavigate();
	let toast = useToast();

	let {semester, tahun_akademik} = useParams();
  	const [bimbingan, setBimbingan] = useState([])
  	const[textTAakad, setTextTAkad]	= useState("")
	const[loading, setLoading] = useState(false);


	useEffect(() => {
		getDataBimbingan();
	}, []); 
   
   const getDataBimbingan = async () => {
		setLoading(true)
		await Axios.get(`dpl/getBimbingan/${tahun_akademik}/${semester}`)
				.then(({data}) => {
					console.info(data)
					setBimbingan(data.bimbingan)
					setTextTAkad(data.tahun_akademik_text)
					setLoading(false)
				})
				.catch(err => {
            const {status, data: {message}} = err.response;
            setLoading(false)
              toast({
                title: `Gagal (${status})!`,
                description: `${message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
          });
	}

	return(
		<>
			{
				loading ? <Loader /> : (
					<Box bg="#fff" p={4} w="full" mt="20px">
					<Button mb={2} size="sm" borderRadius="sm" variant="ghost" onClick={() => navigate("/dpl/bimbingan")} leftIcon={<BiChevronLeft />} colorScheme="red">Kembali</Button>
					<Center>
						<Text borderBottom="2px solid #c7c5c1">{textTAakad}</Text>
					</Center>

					<Button mt={3} onClick={() => navigate("/dpl/inputNilai")} colorScheme='blue' borderRadius="sm" size="sm">Input Nilai </Button>

					<TableContainer mt={5}>
					  <Table variant='simple' size="sm">
					    <Thead>
					      <Tr>
					        <Th>No</Th>
					        <Th>NIM</Th>
					        <Th>MAHASISWA</Th>
					        <Th>SEKOLAH</Th>
					        <Th>PROGRAM</Th>
					        <Th>AKSI</Th>
					      </Tr>
					    </Thead>
					    <Tbody>
					       
					      {
					      	bimbingan.length > 0 ? (
					      		<>
					      		{
					      			bimbingan.map((item, index) => (
					      				<Tr key={index}>
									        <Td>{index + 1}</Td>
									        <Td>{item?.nim}</Td>
									        <Td>{item?.nama_mahasiswa}</Td>
									        <Td>{item?.lokasi}</Td>
									        <Td>{item?.jenis_kegiatan}</Td>
									        <Td>
									        		<Button onClick={() => navigate(`aktivitas/${item.id}`)} size="xs" borderRadius="sm" leftIcon={<FaEye />} colorScheme='teal' variant='solid'>
												    Aktivitas
												  </Button>
									        </Td>
									      </Tr>
					      			))
					      		}
					      		</>
					      	) : (
					      		<Tr>
					      			<Td colspan="6">
					      				<Center><Text fontWeight="bold">Tidak ada data</Text></Center>
					      			</Td>
					      		</Tr>
					      	)
					      }
					      
					    </Tbody>
					  </Table>
					</TableContainer>

				</Box>
				)
			}
		</>
	)
}

export default BimbinganDpl;