import { Box, Container, Flex, Text, Icon, Button, Stack, Heading, HStack, Table, Thead,Tbody, Tfoot, Tr, Th, Td, TableCaption,  Menu,
	Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider, IconButton, Input, Center, Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription, Skeleton, useToast, Image, Spinner, Tooltip  } from "@chakra-ui/react";
import {HamburgerIcon, ChevronDownIcon, ViewIcon, EditIcon, DeleteIcon, WarningIcon, CheckIcon} from "@chakra-ui/icons"
import { Link, useNavigate} from "react-router-dom"

import React, { useEffect, useState } from "react";
import Axios from "../../services/AxiosConfig";
import baseURL from "../../services/BaseUrl.js";


export default function LogbookResult() {

 	 let navigate = useNavigate();
 	 const toast = useToast();

 	  const[logbooks, setLogbooks] = useState([]);
 	  const[loading, setLoading] = useState(false)
 	  const[modalDetail, setModalDetail] = useState(false)
 	  const[modalDelete, setModalDelete] = useState(false)
 	  const[logbookDetail, setLogbookDetail] = useState("")
 	  const[loadingDetail, setLoadingDetail] = useState(false)
 	  const[idLogbook, setIdLogbook] = useState("")
 	  const[loadingDelete, setLoadingDelete] = useState(false)


	  useEffect(() => {
	    getLogbook();
	  },[]);

	  const getLogbook = async () => {
	  	setLoading(true)
	    await Axios.get("mahasiswa/logbook") 
	          .then(({data}) => {
	            setLogbooks(data.logbook);
	            setLoading(false)
	          })
	          .catch(err => {
	            const {status, data: {message}} = err.response;
	            setLoading(false)
	              toast({
	                title: `Gagal (${status})!`,
	                description: `${message}`,
	                status: 'error',
	                duration: 5000,
	                isClosable: true,
	              })
	          })
	  }
 
	  const handleModaDetail = async (id) => {
	  		setModalDetail(true);
	  		setLoadingDetail(true)
	  		await Axios.get("mahasiswa/logbook/detail/"+id)
	  					.then(({data}) => {
	  						setLogbookDetail(data.logbook);
	  						setLoadingDetail(false)
	  					})
	  					.catch(err => {
		            const {status, data: {message}} = err.response;
		            setLoadingDetail(false)
		              toast({
		                title: `Gagal (${status})!`,
		                description: `${message}`,
		                status: 'error',
		                duration: 5000,
		                isClosable: true,
		              })
	          })

	  }

	  const deleteLogbook = (id) => {
	  	setModalDelete(true)
	  	setIdLogbook(id)
	  }

	  const submitDelete = async () => {
	  	setLoadingDelete(true)
	  	await Axios.delete("mahasiswa/logbook/"+idLogbook)
	  				.then(({data}) => {
	  					toast({
		                title: `Berhasil!`,
		                description: `${data.message}`,
		                status: 'success',
		                duration: 5000,
		                isClosable: true,
		              });
	  					getLogbook();
	  					setModalDelete(false)
	  					setLoadingDelete(false)
	  				})
	  				.catch(err => {
		            const {status, data: {message}} = err.response;
								setLoadingDelete(false)
		              toast({
		                title: `Gagal (${status})!`,
		                description: `${message}`,
		                status: 'error',
		                duration: 5000,
		                isClosable: true,
		              })
	          })


	  }



	return(
		<>
				<Box justifyContent="right" as={Flex} w="full">
					<Button size="sm" borderRadius="0" colorScheme="teal" onClick={() => navigate('tambah')}>Tambah Logbook</Button>
				</Box>
				<HStack w="full" px={2} py={3} mt="10px" spacing="10px" fontSize={{base: "10px", md: '12px'}} bg="#F5DF99" mb={3}>
	          <Box w="100px">
	            <Text fontWeight="bold">Kegiatan</Text>
	          </Box>
	          <Box as={Flex} w="150px">
	            <Box>
	              <Text fontWeight="bold">Tanggal Kegiatan</Text>
	            </Box>
	          </Box>
	          <Box w="300px" d={{base: "none", md: "flex"}}>
	            <Text fontWeight="bold">Nama Kegiatan</Text>
	          </Box>
	          <Box w="150px"d={{base: "none", md: "flex"}}>
	            <Text fontWeight="bold" >Tanggal Post</Text>
	          </Box>
	          <Box w="150px">
	            <Text fontWeight="bold">Verifikasi</Text>
	          </Box>
	          <Box w="80px">
	            <Text fontWeight="bold">Aksi</Text>
	          </Box>
	        </HStack>

		      {
		      	loading ? <LoadingSkeleton /> : (
		      		<span>
		      			{
				      	logbooks.map((logbook, index) => (
				      		 <HStack key={index} w="full" px={2} py={3} mt="10px" spacing="10px" fontSize={{base: "10px", md: "12px"}} bg="#fff" mb={1}>
					          <Box w="100px">
					            <Text fontWeight="medium">Minggu ke {logbook.mingguKe}</Text>
					          </Box>
					          <Box as={Flex} w="150px">
					            <Box>
					              <Text fontWeight="medium">{logbook.tgl_kegiatan}</Text>
					            </Box>
					          </Box>
					          <Box w="300px" d={{base: "none", md: "flex"}}>
					            <Text fontWeight="medium">
					            	<div dangerouslySetInnerHTML={{ __html: logbook.nama_kegiatan }} />
					            </Text>
					          </Box>
					          <Box w="150px" d={{base: "none", md: "flex"}}>
					            <Text fontWeight="medium" >{logbook.tanggalPost}</Text>
					          </Box>
					          <Box w="150px">
					          	{
					          		logbook.status === 'not_verified' ? (
					          				<Box as={Flex} alignItems="center">
								          		<Icon as={WarningIcon} color="red.500" mr={1} />
									            <Text fontWeight={{base: "normal", md:"medium"}} fontSize={{base: "10px", md: "12px"}} color="red.500">Belum diverifikasi</Text>
								          	</Box>
					          			) : (
					          				<Box as={Flex} alignItems="center">
								          		<Icon as={CheckIcon} color="green.500" mr={1} /> 
								          		<Tooltip label={`Diverfikasi tanggal ${logbook.verified_date}`} fontSize='xs'>
									            	<Text fontWeight={{base: "normal", md:"medium"}} fontSize={{base: "10px", md: "12px"}} color="green.500">Telah diverifikasi</Text>
															</Tooltip>
								          	</Box>
					          			)
					          	}
					          </Box>
					          <Box w="80px">
					            <Menu>
										<MenuButton
											as={IconButton}
											aria-label='Options'
											icon={<HamburgerIcon />}
											variant='outline'
											size="xs"
											borderRadius="0"
										/>   
										<MenuList>
											<MenuItem icon={<ViewIcon />} onClick={() => handleModaDetail(logbook.id)}>
											Lihat detail
											</MenuItem>
											{
												logbook.status !== 'verified' && (
													<>
														<MenuItem icon={<EditIcon />} onClick={() => navigate("edit/"+logbook.id)}>
															Ubah
															</MenuItem>
															<MenuItem icon={<DeleteIcon />} onClick={() => deleteLogbook(logbook.id)}>
															Hapus
															</MenuItem>
													</>
													)
											}
										</MenuList>
									</Menu>
					          </Box>
					        </HStack>
				      	))
				      }
		      		</span>
		      	)
		      }

		      <ModalDetailLogbook loading={loadingDetail} modalDetail={modalDetail} setModalDetail={setModalDetail} logbook={logbookDetail} />
		      

		      <Modal isOpen={modalDelete} onClose={modalDelete}>
			      <ModalOverlay />
			      <ModalContent>
			        <ModalHeader>Hapus logbook</ModalHeader>
			        <ModalCloseButton disabled={loadingDelete} onClick={() => setModalDelete(false)} />
			        <ModalBody>
			          Apakah anda yakin ingi menghapus logbook ini ?
			        </ModalBody>

			        <ModalFooter>
			          <Button variant='ghost' color="red" isLoading={loadingDelete} disabled={loadingDelete} onClick={() => submitDelete()}>Hapus</Button>
			        </ModalFooter>
			      </ModalContent>
			    </Modal>

		</>
	)
}

const LoadingSkeleton = () => {
	return(
		<Stack>
		  <Skeleton height='40px' />
		  <Skeleton height='40px' />
		  <Skeleton height='40px' />
		  <Skeleton height='40px' />
		  <Skeleton height='40px' />
		  <Skeleton height='40px' />
		  <Skeleton height='40px' />
		  <Skeleton height='40px' />
		</Stack>
	)
}

const ModalDetailLogbook = ({modalDetail, setModalDetail, logbook ,loading}) => {

	const [scrollBehavior, setScrollBehavior] = React.useState('inside')

  const btnRef = React.useRef(null)

	return(
		<Modal isOpen={modalDetail} onClose={modalDetail} size={"full"} finalFocusRef={btnRef}  scrollBehavior={scrollBehavior}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
        	<Center>
        		Detail Logbook
        	</Center>
        </ModalHeader>
        <ModalCloseButton onClick={() => setModalDetail(false)} />
        <ModalBody>
          <Container maxW="800">
          	{
          		loading ? (
          			<Box minH="70vh" as={Flex} justifyContent="center" alignItems="center">
          				<Spinner color='#FAC213' />
          			</Box>

          		) : (

          			<>
          				<Box>
			          		<Text fontWeight="bold" mb={2}>
			          			Nama kegiatan / sub kegitan
			          		</Text>
			          		<div dangerouslySetInnerHTML={{ __html: logbook.nama_kegiatan }} />
			          	</Box>

			          	<Box mt={5}>
			          		<Text fontWeight="bold" mb={2}>
			          			Tujuan kegiatan
			          		</Text>
			          		<div dangerouslySetInnerHTML={{ __html: logbook.tujuan_kegiatan }} />
			          	</Box>

			          	<Box mt={5}>
			          		<Text fontWeight="bold" mb={2}>
			          			Catatan
			          		</Text>
			          		<div dangerouslySetInnerHTML={{ __html: logbook.catatan }} />
			          	</Box>

			          	<Box mt={5}>
			          		<Text fontWeight="bold" mb={2}>
			          			Pemecahan masalah
			          		</Text>
			          		<div dangerouslySetInnerHTML={{ __html: logbook.pemecahan_masalah }} />
			          	</Box>

			          	<Box mt={5}>
			          		<Text fontWeight="bold" mb={2}>
			          			Kesimpulan
			          		</Text>
			          		<div dangerouslySetInnerHTML={{ __html: logbook.kesimpulan }} />
			          	</Box>

			          	<Box mt={5}>
			          		<Text fontWeight="bold" mb={2}>
			          			Rencana kegiatan selanjutnya
			          		</Text>
			          		<div dangerouslySetInnerHTML={{ __html: logbook.rencana }} />
			          	</Box>

			          	<Box mt={5}>
			          		<Text fontWeight="bold" mb={2}>
			          			Lampiran
			          		</Text>

			          		{
			          			logbook.gambar ? (
			          				<Box my={3} >
			          					{
			          						logbook.gambar.map((item, index) => (
						          				<Box key={index} mb={3} w="full">
														<Text mt={10} color="tomato">Gambar {index + 1}</Text>
						          					<Image src={baseURL+'assets/upload/gambar/logbook_kegiatan/'+item.gambar} alt="lampiran" objectFit='cover' />
														<br />
						          				</Box>
						          			))
			          					}
			          				</Box>
			          				) : ""
			          		}

			          	</Box>
          			</>
          		)
          	}

          </Container>
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' onClick={() => setModalDetail(false)}>
            Tutup
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>)
}

