
import { useState, useEffect } from "react";
import Axios from "services/AxiosConfig";
import AlertMessage from "helpers/AlertMessage"
import { Text, Center, useToast, Button, Link, Box, Flex, Stack, Avatar, Icon, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer } from "@chakra-ui/react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { BiChevronLeft } from "react-icons/bi";

import { Menu } from '@arco-design/web-react';
import { IconArchive, IconFile } from '@arco-design/web-react/icon';

const MenuItem = Menu.Item;

export default function AktivitasMhs() {

	let navigate 	= useNavigate();
	const { id } 	= useParams();
	let toast 		= useToast()

	const[mahasiswaDetail, setMahasiswaDetail] = useState("");
	const[loading, setLoading] = useState(false);

	useEffect(() => {
		getDataMahasiswaDetail();
	},[]);

	const getDataMahasiswaDetail = async () => {
		setLoading(true)
		await Axios.get("dpl/getMahasiswaDetail/"+id)
			  .then(({data}) => {
			  	setMahasiswaDetail(data.mahasiswa)
			  	setLoading(false)
			  })
			  .catch(err => {
	            const {status, data: {message}} = err.response;
	            setLoading(false)
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           })
	          })
	}

	return(
		<Box>
			<Button mb={2} size="sm" borderRadius="sm" variant="ghost" onClick={() => navigate(-1)} leftIcon={<BiChevronLeft />} colorScheme="red">Kembali</Button>
			
			<Stack direction={['column', 'row']} spacing="20px">

				<Box w="25%" bg="#fff" justifyContent="center" flexDir="column" alignItems="center" as={Flex} p={3}>
					<Icon as={FaUserCircle} w="20" h="20" color="#839AA8" />
					<Text fontWeight="bold" fontSize="xl">{mahasiswaDetail?.nama_lengkap}</Text>
					<Text mt="10px" fontSize="13px">{mahasiswaDetail?.nim}</Text>
					<Center>
							<Text fontSize="13px">{mahasiswaDetail?.nama_prodi}</Text>
					</Center>
				</Box>

				
				<Box w="full" bg="#fff" >
					<Box p={3} bg="#F5DF99">
						<Text fontWeight="bold">Informasi Lainya</Text>
					</Box>
					<TableContainer p={4}>
					  <Table variant='simple' size="sm">
					    <Tr>
					    	<Th w="100px">Nama Sekolah</Th>
					    	<Td> : {mahasiswaDetail?.lokasi}</Td>
					    </Tr>
					    <Tr>
					    	<Th>Jenis Kepesertaan</Th>
					    	<Td> : {mahasiswaDetail?.jenis_kepesertaan}</Td>
					    </Tr>
					    <Tr>
					    	<Th>Nilai Pembimbing</Th>
					    	<Td> : {mahasiswaDetail?.grade}</Td>
					    </Tr>
					    <Tr>
					    	<Th>NO HP</Th>
					    	<Td> : {mahasiswaDetail?.no_hp}</Td>
					    </Tr>
					  </Table>
					</TableContainer>					
				</Box>

			</Stack>

			<Box mt={"20px"} w="full">
				<Stack direction={['column', 'row']} spacing="20px">
					<Box w="190px" >
						<Box bg="#F5DF99" p={3}>
							<Text fontWeight="bold">Aktivitas</Text>
						</Box>
							<div
						      className='menu-demo'
						      style={{
						        width: "100%",
						      }}
						    >

					      <Menu
					        style={{ width: "100%", height: '100%' }}
					        defaultSelectedKeys={['0_1']}
					      >
					      <MenuItem key='0_1' onClick={() => navigate('/dpl/bimbingan/aktivitas/'+mahasiswaDetail?.id_pendaftar)}>
					        <IconArchive />
					        Logbook
					      </MenuItem>
					       <MenuItem key='46' onClick={() => navigate(`/dpl/bimbingan/aktivitas/${mahasiswaDetail?.id_pendaftar}/laporan`)}>
					        <IconFile />
					        	Laporan
					      </MenuItem>
					      </Menu>
					    </div>
					</Box>
					<Box w="75%">
						<Outlet />
					</Box>
				</Stack>
			</Box>

			
		</Box>		
	);
}