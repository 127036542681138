import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Axios from "axios";

import "@arco-design/web-react/dist/css/arco.css";
import { ConfigProvider } from "@arco-design/web-react";
import enID from "@arco-design/web-react/es/locale/id-ID";
import ProtectedRoutes from "services/ProtectedRoutes";

import Login from "./views/Login";
import ProgramHome from "./views/Program";

// mahasiswa
import Dashboard from "./views/mhs/Dashboard";
import MhsProfile from "./views/mhs/Profile";
import Layout from "./views/mhs/layout";
import Program from "./views/mhs/Program";
import Info from "./components/mahasiswa/Info";
import PilihanProgram from "./views/mhs/PilihanProgram";
import Kegiatan from "./views/mhs/Kegiatan";
import Notifikasi from "./views/mhs/Notifikasi";
import StatusPendaftaran from "./views/mhs/StatusPendaftaran";
import KegiatanKu from "./views/mhs/Kegiatanku";
import Logbook from "./views/mhs/Logbook";
import LogbookResult from "./views/mhs/LogbookResult";
import LaporanMhs from "./views/mhs/Laporan";
import TambahLogbook from "./views/mhs/TambahLogbook";
import EditLogbook from "./views/mhs/EditLogbook";
import MhsDocument from "views/mhs/MhsDocument";
import MhsFAQ from "views/mhs/MhsFAQ";

// admin

import DashboardAdmin from "./views/admin/Dashboard";
import Peserta from "./views/admin/Peserta";
import ResultPeserta from "./views/admin/ResultPeserta";
import Bimbingan from "views/admin/Bimbingan";
import BimbinganResult from "views/admin/BimbinganResult";
import BimbinganEdit from "views/admin/BimbinganEdit";
import Pendaftaran from "views/admin/Pendaftaran";
import PendaftaranResult from "views/admin/PendaftaranResult";
import Pengaturan from "views/admin/Pengaturan";
import Sekolah from "views/admin/Sekolah";
import ProgramAdmin from "views/admin/Program";
import TambahProgram from "views/admin/TambahProgram";
import EditPeserta from "views/admin/EditPeserta";

// DPL
import DPLDashboard from "views/dpl/Dashboard";
import DashboardResultDPL from "views/dpl/DashboardResultDPL";
import DPLBimbingan from "views/dpl/Bimbingan";
import DPLAktivitasMahasiswa from "views/dpl/AktivitasMhs";
import DPLLaporanMhs from "views/dpl/LogbookMhs";
import DPLBimbinganLaporan from "views/dpl/DPLBimbinganLaporan";
import DPLInputNilai from "views/dpl/InputNilai";
import DPLFAQ from "views/dpl/FAQ";
import BimbinganDpl from "views/dpl/BimbinganDpl";
// import DPLAktivitasMahasiswaSearch from 'views/dpl/search/aktivitasMhs'
// import DPLLaporanMhsSearch from "views/dpl/search/LogbookMhs"

import NotFound from "./views/NotFound.jsx";

import { extendTheme } from "@chakra-ui/react";
import Callback from "views/Callback";

const theme = extendTheme({
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
});

Axios.defaults.baseURL = process.env.REACT_APP_BE_URL;

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ConfigProvider locale={enID}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/auth/callback" element={<Callback />} />
            <Route path="/program" element={<ProgramHome />} />
            <Route path="/login" element={<Login />} />

            <Route element={<ProtectedRoutes />}>
              <Route path="/mhs" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="beranda" element={<Dashboard />}>
                  <Route index element={<Info />} />
                  <Route path="profile" element={<MhsProfile />} />
                  <Route path="document" element={<MhsDocument />} />
                  <Route path="faq" element={<MhsFAQ />} />
                </Route>
                <Route path="program" element={<Program />} />
                <Route path="program/:slug" element={<PilihanProgram />} />
                <Route path="kegiatan" element={<Kegiatan />}>
                  <Route index element={<StatusPendaftaran />} />
                  <Route path="status" element={<StatusPendaftaran />} />
                  <Route path="kegiatanKu" element={<KegiatanKu />} />
                </Route>
                <Route path="logbook" element={<Logbook />}>
                  <Route index element={<LogbookResult />} />
                  <Route path="laporan" element={<LaporanMhs />} />
                  <Route path="tambah" element={<TambahLogbook />} />
                  <Route path="edit/:id" element={<EditLogbook />} />
                </Route>
                <Route path="notifikasi" element={<Notifikasi />} />
              </Route>
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/admin" element={<DashboardAdmin />}>
                <Route path="peserta" element={<Peserta />} />
                <Route
                  path="peserta/:tahun_akademik/:semester"
                  element={<ResultPeserta />}
                />
                <Route
                  path="peserta/:tahun_akademik/:semester/:id/edit"
                  element={<EditPeserta />}
                />
                <Route path="bimbingan" element={<Bimbingan />} />
                <Route
                  path="bimbingan/:tahun_akademik/:semester"
                  element={<BimbinganResult />}
                />
                <Route
                  path="bimbingan/:tahun_akademik/:semester/:id_dosen/edit"
                  element={<BimbinganEdit />}
                />
                <Route path="pendaftaran" element={<Pendaftaran />} />
                <Route
                  path="pendaftaran/:nim"
                  element={<PendaftaranResult />}
                />
                <Route path="sekolah" element={<Sekolah />} />
                <Route path="program" element={<ProgramAdmin />} />
                <Route path="program/tambah" element={<TambahProgram />} />
                <Route path="pengaturan" element={<Pengaturan />} />
              </Route>
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/dpl" element={<DPLDashboard />}>
                <Route index element={<DashboardResultDPL />} />
                <Route path="bimbingan" element={<DPLBimbingan />} />
                <Route path="inputNilai" element={<DPLInputNilai />} />
                <Route
                  path="bimbingan/aktivitas/:id"
                  element={<DPLAktivitasMahasiswa />}
                >
                  <Route index element={<DPLLaporanMhs />} />
                  <Route path="laporan" element={<DPLBimbinganLaporan />} />
                </Route>
                <Route
                  path="bimbingan/:tahun_akademik/:semester"
                  element={<BimbinganDpl />}
                />
                <Route
                  path="bimbingan/:tahun_akademik/:semester/aktivitas/:id"
                  element={<DPLAktivitasMahasiswa />}
                >
                  <Route index element={<DPLLaporanMhs />} />
                </Route>
                <Route path="faq" element={<DPLFAQ />} />
              </Route>
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
